<template>
  <div class="list-menu">
    <div class="text-h3 font-weight-semibold">
      {{ $t("lists.header.create") }}
    </div>
    <div
      class="d-flex flex-row align-end"
      :class="{ 'w-50': $vuetify.breakpoint.smAndUp }"
    >
      <v-text-field
        :placeholder="$t('lists.insert')"
        hide-details="auto"
        class="text-body-2 rounded-pill mr-0"
        v-model="listName"
        background-color="white"
        outlined
        dense
      >
        <template v-slot:append class="mt-0 mr-0">
          <v-fade-transition leave-absolute>
            <v-btn
              v-ripple="false"
              depressed
              large
              class="create-list-btn primary ml-3 rounded-pill mt-0"
              width="98"
              heigth="40"
              v-on:click="createList()"
              :disabled="createDisabled"
              >{{ $t("lists.button.create") }}</v-btn
            >
          </v-fade-transition>
        </template>
      </v-text-field>
    </div>
    <v-list class="pa-0 default--text mt-8">
      <div class="text-h4 font-weight-semibold mb-5">
        {{ $t("lists.header.automaticLists") }}
        <v-tooltip right content-class="automatic-list-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-2" v-bind="attrs" v-on="on">$info</v-icon>
          </template>
          <span>{{ $t("lists.header.automaticListsTooltip") }}</span>
        </v-tooltip>
      </div>
      <v-list-item
        class="rounded-sm text-body-2 font-weight-semibold py-2"
        :to="{ name: 'List', params: { listId: 'preferred' } }"
      >
        <div class="w-100">
          <i18n
            class="primary--text"
            :path="hasFavorites ? 'list.preferred' : 'list.bestselling'"
          />
        </div>
      </v-list-item>

      <div class="text-h4 font-weight-semibold mt-8 mb-5">
        {{ $t("lists.header.myLists") }}
        <v-tooltip right content-class="my-list-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-icon role="button" class="ml-2" v-bind="attrs" v-on="on"
              >$info</v-icon
            >
          </template>
          <span>{{ $t("lists.header.myListsTooltip") }}</span>
        </v-tooltip>
      </div>

      <v-list-item class="list-header">
        <v-row no-gutters class="mt-2 default--text font-weight-semibold">
          <v-col cols="4">{{ $t("lists.header.name") }}</v-col>
          <v-col cols="2" class="pl-2">
            {{ $t("lists.header.productsQty") }}</v-col
          >
        </v-row>
      </v-list-item>
      <v-list-item
        v-for="list in listsData"
        :key="list.id"
        class="list-table rounded-sm my-2 py-2"
        :class="{ 'list-default': list.isDefault }"
      >
        <router-link
          class="w-100 h-100 text-decoration-none black--text"
          :to="{ name: 'List', params: { listId: list.id } }"
        >
          <v-row
            no-gutters
            class="text-body-2 align-center"
            v-if="!list.isEdit"
          >
            <v-col cols="4">
              <span class="font-weight-semibold primary--text">{{
                list.name
              }}</span>
            </v-col>
            <v-col cols="2">
              {{ list.size }}
            </v-col>
            <v-col
              cols="6"
              class="d-flex justify-end align-center mt-6 mt-sm-0"
            >
              <v-tooltip top v-if="!list.isDefault">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    outlined
                    depressed
                    v-bind="attrs"
                    width="45"
                    heigth="45"
                    min-width="45"
                    min-heigth="45"
                    v-on="on"
                    class="ml-1 rounded-circle"
                    :aria-label="$t('lists.button.setDefault')"
                    @click.prevent="setDefault(list.id)"
                  >
                    <v-icon color="primary">$starLight</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("lists.button.setDefault") }}</span>
              </v-tooltip>
              <v-tooltip top v-if="list.isDefault">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    outlined
                    depressed
                    v-bind="attrs"
                    width="45"
                    heigth="45"
                    min-width="45"
                    min-heigth="45"
                    v-on="on"
                    class="ml-1 rounded-circle"
                    @click.prevent=""
                    :aria-label="$t('lists.button.default')"
                  >
                    <v-icon>$star</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("lists.button.default") }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    outlined
                    depressed
                    v-bind="attrs"
                    width="45"
                    heigth="45"
                    min-width="45"
                    min-heigth="45"
                    v-on="on"
                    class="ml-1 rounded-circle"
                    @click.prevent="addAllToCart(list.id)"
                    :aria-label="$t('lists.button.addAllToCart')"
                  >
                    <v-icon>$cartArrowDown</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("lists.button.addAllToCart") }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    depressed
                    v-bind="attrs"
                    width="45"
                    heigth="45"
                    min-heigth="45"
                    min-width="45"
                    v-on="on"
                    @click.prevent="setEdit(list)"
                    class="primary--text ml-1 rounded-circle"
                    :aria-label="$t('lists.button.edit')"
                  >
                    <v-icon>$edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("lists.button.edit") }}</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    width="45"
                    heigth="45"
                    min-width="45"
                    v-bind="attrs"
                    v-on="on"
                    class="primary--text ml-1 rounded-circle"
                    @click.prevent="removeList(list.id, list.name)"
                    :disabled="list.isDefault"
                    :aria-label="$t('lists.button.delete')"
                    icon
                    color="default"
                  >
                    <v-icon>$trashXmark</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("lists.button.delete") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row no-gutters align="center" v-if="list.isEdit">
            <!-- label="Inserisci il nome" -->
            <v-col cols="8">
              <v-text-field
                hide-details="auto"
                dense
                outlined
                class="text-body-2 pr-2"
                v-model="list.tempName"
                v-on:click.prevent="() => {}"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="d-flex flex-row">
              <v-btn
                icon
                v-on:click.prevent="confirmEdit(list)"
                :aria-label="$t('common.ok')"
              >
                <v-icon color="primary">$done</v-icon>
              </v-btn>
              <v-btn
                icon
                v-on:click.prevent="undoEdit(list)"
                :aria-label="$t('common.close')"
              >
                <v-icon color="primary">$close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </router-link>
      </v-list-item>
    </v-list>
  </div>
</template>
<style lang="scss">
.list-menu {
  .list-default {
    border: 1px solid var(--v-primary-base);
  }
  .list-table {
    .v-btn {
      width: 45px !important;
      height: 45px !important;
      padding: 0 !important;
    }
  }
  .v-input__append-inner,
  .v-input .v-input__slot {
    margin-top: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
  .v-list {
    background-color: transparent;
    .v-list-item:not(.list-header) {
      background-color: $white;
      border: 1px solid var(--v-grey-lighten1);
    }
  }
}
</style>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import list from "~/mixins/list";

export default {
  name: "ListsMenu",
  mixins: [list],
  props: {
    listsData: { type: Array, required: true }
  },
  computed: {
    ...mapGetters({
      hasFavorites: "cart/hasFavorites"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist",
      setCartItemInfo: "cart/setCartItemInfo"
    }),
    createList() {
      let name = this.listName;
      this.$emit("createlist", { name });
      this.listName = "";
    },
    setDefault(listId) {
      this.$emit("setdefault", listId);
    },
    removeList(listId, listName) {
      this.$emit("remove", { listId, listName });
    },
    setEdit(list) {
      Vue.set(list, "isEdit", true);
      Vue.set(list, "tempName", list.name);
    },
    undoEdit(list) {
      list.isEdit = false;
      list.tempName = null;
    },
    confirmEdit(list) {
      this.$emit("rename", list);
    },
    async addAllToCart(listId) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        const result = await this.addProductsFromWishlist(listId);
        if (result && result.cartItems && result.cartItems.length > 0) {
          this.setCartItemInfo({
            items: result.cartItems,
            name: "accept_alternatives",
            value: global.config.acceptAlternatives
          });
        }
      }
    },
    setOpenList(listId) {
      this.setOpenedListId(listId);
    }
  }
};
</script>
